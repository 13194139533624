import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
const Meters = ({ meters, meter, setMeter, setUpdated, setFilters }) => {
  console.log("Metter ______________", meter);
  return (
    <FormControl fullWidth>
      <Typography id="demo-simple-select-label">Meter</Typography>
      <Autocomplete
        multiple
        id="tags-outlined"
        limitTags={1}
        onChange={(event, newValue) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, meter: newValue },
          }));
          setUpdated((prev) => prev + 1);
        }}
        options={meters || []}
        getOptionLabel={(option) => option?.name}
        sx={{
          width: "100%",
        }}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} />}
      />
    </FormControl>
  );
};

export default Meters;
