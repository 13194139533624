import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const SelectCell = ({ data, rowid, def, handleChange }) => {
  return (
    <FormControl fullWidth>
      <Select
        value={def || 1}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        onChange={(e) => handleChange(e, rowid)}
      >
        <MenuItem value="" disabled>
          Select...
        </MenuItem>
        {data?.map((item) => (
          <MenuItem value={item.id}>{item.title}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectCell;
