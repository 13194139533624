import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Box, Divider, Grid, IconButton, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Container } from "@mui/system";

const columns = [
  { id: "title", label: "Titles", used: "title" },
  { id: "type", label: "Types", used: "details" },
  { id: "country", label: "Countries", used: "country" },
  { id: "region", label: "Regions", used: "details" },
  { id: "meter", label: "Meters", used: "details" },
  { id: "bpm", label: "BPM", used: "details" },
  { id: "modus", label: "Modus", used: "details" },
  { id: "level", label: "Level", used: "details" },
  { id: "tonality", label: "Tonalities", used: "details" },
  { id: "material", label: "Materials", used: "material" },
];
export default function OneTune({ tune }) {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(tune);
  return (
    <div>
      <IconButton
        sx={{ color: "#000" }}
        aria-label="add to shopping cart"
        size="small"
        onClick={handleClickOpen}
      >
        <RemoveRedEyeIcon fontSize="inherit" />
      </IconButton>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        fullWidth={true}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">
          Tune ID 
        </DialogTitle>
        <Divider /> */}
        <DialogContent>
          <Container
            sx={{
              minWidth: "100%",
            }}
          >
            {columns.map((item, key) => (
              <Grid
                container
                sx={{
                  mb: 2,
                  pb: 2,
                  borderBottom: "1px solid #eee",
                }}
              >
                <Grid item xs={12} md={4}>
                  <Typography fontWeight={800} variant="h6">
                    {item.label}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  {item?.used == "details" ? (
                    <>
                      {tune?.[item?.id]?.map((v, k) => (
                        <Typography variant="body1">
                          {v?.[item?.id + "_" + item?.used]?.name} --
                          {item?.id === "tonality" && v?.modus_details?.name}
                        </Typography>
                      ))}
                    </>
                  ) : (
                    <>
                      {item?.used == "material" ? (
                        <>
                          {tune?.[item?.id]?.map((v, k) => (
                            <Box
                              sx={{
                                width: "100%",
                                background: "#eee",
                                p: 1,
                                mb: 1,
                              }}
                            >
                              <Typography fontWeight={800}>
                                {v?.name}
                              </Typography>
                              <Typography fontWeight={800}>
                                {v?.type_details?.type} - {v?.link}
                              </Typography>
                            </Box>
                          ))}
                        </>
                      ) : (
                        <>
                          {tune?.[item?.id]?.map((v, k) => (
                            <Typography variant="body1">
                              {v?.[item?.used]?.name != undefined
                                ? v?.[item?.used]?.name
                                : v?.[item?.used]}{" "}
                            </Typography>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            ))}
          </Container>
          <DialogContentText></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
