import logo from "./logo.svg";
import "./App.css";
import Routers from "./Routers/Routers";
import "./config";

function App() {
  return <Routers />;
}

export default App;
