import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
const Title = ({ titles, setTitles, errors }) => {
  const [addTitle, setAddTitle] = React.useState(null);
  const [clicked, setClicked] = React.useState(false);
  const [counter, setCounter] = React.useState(0);

  const handleClickAddNewTitle = () => {
    if (addTitle !== null && addTitle !== "") {
      setTitles([
        ...titles,
        {
          id: counter,
          title: addTitle,
        },
      ]);
      setCounter((prev) => prev + 1);
      setClicked(true);
      setTimeout(() => {
        setClicked(false);
        setAddTitle("");
      }, 2000);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <FormControl sx={{ m: 1, width: "100%" }} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">
        Add new title
      </InputLabel>
      <FilledInput
        onInput={(e) => setAddTitle(e.target.value)}
        value={addTitle}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            handleClickAddNewTitle();
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        disabled={clicked}
        variant="filled"
        endAdornment={
          <InputAdornment position="end">
            {clicked ? (
              <CheckCircleOutlineIcon sx={{ color: "green" }} />
            ) : (
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickAddNewTitle}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <ControlPointIcon />
              </IconButton>
            )}
          </InputAdornment>
        }
        label="Add new Title"
      />
      <FormHelperText error={true} id="filled-weight-helper-text">
        {errors?.titles}
      </FormHelperText>
    </FormControl>
  );
};

export default Title;
