import { Autocomplete, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
const Countries = ({ country, setCountry }) => {
  const currentUser = Authentication.getCurrentUser();
  const [countries, setCountries] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/countries/", currentUser.auth_token).then(
      (result) => setCountries(result)
    );
  }, []);
  console.log(country);
  return (
    <Box
      sx={{
        maxWidth: "500px",
        width: "100%",
        display: "block",
        margin: "auto",
        mt: 2,
      }}
    >
      {countries !== null && (
        <Autocomplete
          multiple
          id="tags-outlined"
          limitTags={1}
          onChange={(event, newValue) => {
            setCountry(newValue);
          }}
          options={countries || []}
          defaultValue={country}
          getOptionLabel={(option) => option?.name}
          sx={{
            maxWidth: 300,
          }}
          filterSelectedOptions
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option.name} ({option.code})
            </Box>
          )}
          renderInput={(params) => (
            <TextField {...params} placeholder="Countries.." />
          )}
        />
      )}
    </Box>
  );
};

export default Countries;
