import React from "react";
import { Autocomplete, Chip, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";

import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";

const Types = ({ types, setTypes, errors, profile, tune }) => {
  const currentUser = Authentication.getCurrentUser();

  const [type, setType] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/types/", currentUser.auth_token).then(
      (result) => setType(result)
    );
  }, []);
  const handleTypeDelete = (uid) => {
    ServerRequests.delete_token(
      "tune/type_delete/" + uid + "/" + tune + "/",
      currentUser.auth_token
    ).then((response) => console.log(response));
  };
  return (
    <Box sx={{ m: 1, width: "100%" }}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={type || []}
        disableClearable={true}
        defaultValue={types}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        onChange={(event, newValue, reason, detail) => {
          if (reason === "removeOption") {
            if (detail.option.uid != undefined) {
              console.log(detail.option);
              handleTypeDelete(detail.option.uid);
            }
          }
          setTypes(newValue);
        }}
        freeSolo
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.name || option}
              {...getTagProps({ index })}
              disabled={
                (option?.editor !== undefined) &
                (option?.editor !== profile?.id)
              }
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Tunes Types"
            error={errors?.types ? true : false}
            helperText={errors?.types}
            placeholder="Options..."
          />
        )}
      />
    </Box>
  );
};

export default Types;
