import React from "react";
import { Autocomplete, InputLabel, TextField } from "@mui/material";
import { Box } from "@mui/system";

import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";

const Types = ({ types, setTypes, errors }) => {
  const currentUser = Authentication.getCurrentUser();

  const [type, setType] = React.useState(null);
  React.useEffect(() => {
    ServerRequests.get_token("home/types/", currentUser.auth_token).then(
      (result) => setType(result)
    );
  }, []);

  return (
    <Box sx={{ m: 1, width: "100%" }}>
      <Autocomplete
        multiple
        id="tags-filled"
        limitTags={2}
        options={type || []}
        defaultValue={types}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }

          return option.name;
        }}
        onChange={(event, newValue) => {
          setTypes(newValue);
        }}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            label="Tunes Types"
            error={errors?.types ? true : false}
            helperText={errors?.types}
            placeholder="Options..."
          />
        )}
      />
    </Box>
  );
};

export default Types;
