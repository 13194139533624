import { CssBaseline } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "../../Base/Header/Header";
import Sidebar from "../../Base/Sidebar/Sidebar";
import Body from "../../Elements/Home/Body";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Main = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#eee",
}));
const Home = () => {
  return (
    <CssBaseline>
      <Container
        sx={{
          minHeight: "100vh",
          backgroundColor: "#eee",
        }}
        maxWidth={false}
        disableGutters
      >
        <Header />

        <Container maxWidth="xl" sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container flexDirection="row" spacing={2}>
            <Grid
              item
              xs={false}
              md={2}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Sidebar />
            </Grid>
            <Grid item xs={12} md={10}>
              <Main sx={{ mb: 4 }}>
                <Body />
              </Main>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </CssBaseline>
  );
};

export default Home;
