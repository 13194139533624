import { Chip, CssBaseline, Divider, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Header from "../../Base/Header/Header";
import Sidebar from "../../Base/Sidebar/Sidebar";
import Filters from "../../Elements/Tunes/Filters";
import TunesTable from "./TunesTable";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const Main = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  backgroundColor: "#eee",
}));
const Tunes = () => {
  const currentUser = Authentication.getCurrentUser();
  const [player, setPlayer] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [tunes, setTunes] = React.useState(null);
  const [updated, setUpdated] = React.useState(0);
  const [filters, setFilters] = React.useState({
    combinations: 0,
    users: [],
  });
  const [types, setTypes] = React.useState(null);
  const [competences, setCompetence] = React.useState(null);
  const [country, setCountry] = React.useState("");
  const [countries, setCountries] = React.useState(null);
  const [fetched, setFetched] = React.useState(false);
  const [disablechoose, setDisablechoose] = React.useState(false);
  React.useEffect(() => {
    setDisablechoose(true);
    setFetched(true);
    if (updated > 0) {
      ServerRequests.post_token("home/tune/", currentUser.auth_token, filters)
        .then((result) => result.json())
        .then((response) => {
          setTunes(response);
          setFetched(false);
          setDisablechoose(false);
        });
    } else {
      ServerRequests.get_token("home/tune/", currentUser.auth_token).then(
        (result) => {
          setTunes(result);
          setFetched(false);
          setDisablechoose(false);
        }
      );
      ServerRequests.get_token("home/competence/", currentUser.auth_token).then(
        (result) => {
          setCompetence(result);
          setFetched(false);
          setDisablechoose(false);
        }
      );
      ServerRequests.get_token("home/types/", currentUser.auth_token).then(
        (result) => {
          setTypes(result);
          setFetched(false);
          setDisablechoose(false);
        }
      );
      ServerRequests.get_token("home/countries/", currentUser.auth_token).then(
        (result) => {
          setFetched(false);
          setCountries(result);
          setDisablechoose(false);
        }
      );
    }
  }, [updated]);

  const handleClose = () => {
    if (player !== null) setOpen(false);
  };
  console.log(updated);
  const handleChose = () => {
    setPlayer({ naem: "hi" });
    setOpen(false);
  };

  const handleTitleSearch = (title) => {
    setFilters({ ...filters, title: title });
    setUpdated((prev) => prev + 1);
  };
  const handleResourceFilter = (resources) => {
    setFilters({ ...filters, resources: resources });
    setUpdated((prev) => prev + 1);
  };
  const handleSorting = (e) => {
    setFilters({ ...filters, sort: e });
    setUpdated((prev) => prev + 1);
  };
  const handleType = (value) => {
    setFilters({ ...filters, type: value });
    setUpdated((prev) => prev + 1);
  };
  const handleCountry = (value) => {
    setFilters({ ...filters, country: value });
    setUpdated((prev) => prev + 1);
  };
  const handleCompetenceChange = (value) => {
    setFilters({ ...filters, competence: value });
    setUpdated((prev) => prev + 1);
  };
  function getDifference(array1, array2) {
    console.log(array1, array2);
    if (array1 === undefined) {
      array1 = [];
    }
    if (array2 === undefined) {
      array2 = [];
    }
    return array1.filter(
      (object1) => !array2.some((object2) => object1.id === object2.id)
    );
  }
  const renderNonExistUser = (nx) => {
    console.log(nx);
    const difference = [
      ...getDifference(nx, filters?.users),
      ...getDifference(filters?.users, nx),
    ];
    console.log(difference);
    return (
      <>
        {difference.length === 0 && "They all can perform together"}
        {difference?.map((user) => (
          <Chip
            sx={{ background: "#ff4545c2" }}
            label={user.first_name + " " + user.last_name}
          />
        ))}
      </>
    );
  };
  console.log(tunes);
  return (
    <CssBaseline>
      <Container
        sx={{
          minHeight: "100vh",
          backgroundColor: "#eee",
        }}
        maxWidth={false}
        disableGutters
      >
        <Header />

        <Container maxWidth="xl" sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container flexDirection="row" spacing={2}>
            <Grid
              item
              xs={2}
              sx={{
                display: { xs: "none", md: "block" },
              }}
            >
              <Sidebar />
            </Grid>
            <Grid item xs={12} md={10}>
              <Main sx={{ mb: 10 }}>
                <Filters
                  handleTitleSearch={handleTitleSearch}
                  handleResourceFilter={handleResourceFilter}
                  handleSorting={handleSorting}
                  setUpdated={setUpdated}
                  setFilters={setFilters}
                  types={types}
                  filters={filters}
                  handleType={handleType}
                  competences={competences}
                  countries={countries}
                  disablechoose={disablechoose}
                  setDisablechoose={setDisablechoose}
                  handleCompetenceChange={handleCompetenceChange}
                  handleCountry={handleCountry}
                />
                {tunes !== null && (
                  <>
                    {tunes !== undefined && (
                      <>
                        {Array.isArray(tunes[0]) ? (
                          <>
                            {tunes[0]?.length === 0 && (
                              <Typography variant="body2">
                                There is no common tunes between those players.
                                You can use the filters to choose the number of
                                players
                              </Typography>
                            )}
                            {tunes[0]?.map((item, key) => (
                              <>
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="space-around"
                                  alignItems="stretch"
                                >
                                  <Grid item>
                                    <Typography>Performing:</Typography>
                                    {tunes[1][key].map((user) => (
                                      <Chip
                                        label={
                                          user.first_name + " " + user.last_name
                                        }
                                        sx={{
                                          background: "#75ff45c2",
                                        }}
                                      />
                                    ))}
                                  </Grid>
                                  <Grid item>
                                    <Typography>Listening:</Typography>
                                    {renderNonExistUser(tunes[1][key])}
                                  </Grid>
                                </Grid>

                                <Box sx={{ mb: 2 }} />
                                <TunesTable
                                  competences={competences}
                                  setUpdated={setUpdated}
                                  tunes={item}
                                  fetched={fetched}
                                />
                                <Box sx={{ mb: 2 }} />
                              </>
                            ))}
                          </>
                        ) : (
                          <TunesTable
                            competences={competences}
                            setUpdated={setUpdated}
                            tunes={tunes}
                            fetched={fetched}
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Main>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </CssBaseline>
  );
};

export default Tunes;
