import * as React from "react";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
const Levels = ({ setFilters, levels, setLevel, setUpdated }) => {
  return (
    <FormControl fullWidth>
      <Typography id="demo-simple-select-label">Level</Typography>
      <Autocomplete
        multiple
        id="tags-outlined"
        limitTags={1}
        onChange={(event, newValue) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, level: newValue },
          }));
          setUpdated((prev) => prev + 1);
        }}
        options={levels || []}
        getOptionLabel={(option) => "Level :" + option?.name}
        sx={{
          width: "100%",
        }}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} />}
      />
    </FormControl>
  );
};

export default Levels;
