import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Container } from "@mui/system";
import { Divider, Grid, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
import Autocomplete from "@mui/material/Autocomplete";
import Types from "./Types";
import Date from "./Date";
export default function Authors({ errors, setDate, date, setStore, store }) {
  const currentUser = Authentication.getCurrentUser();
  const [open, setOpen] = React.useState(false);
  const [extend, setExtend] = React.useState(false);
  const [user, setUser] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setExtend(false);
  };
  const handleSave = () => {
    ServerRequests.post_token(
      "home/learned_from/",
      currentUser.auth_token,
      store
    ).then((result) => {
      if (result.ok) {
        setStore({});
        setOpen(false);
      }
    });
  };

  React.useEffect(() => {
    if (user) {
      ServerRequests.post_token("home/users_fetch/", currentUser.auth_token, {
        word: user,
      })
        .then((result) => result.json())
        .then((response) => {
          console.log(response);
          setUsers((prev) => response);
        });
    }
  }, [user]);
  console.log(store);
  return (
    <Container
      sx={{
        border: "1px solid #ddd",
        p: 1,
        m: 1,
      }}
    >
      <Date date={date} setDate={setDate} errors={errors} />
      {extend === false ? (
        <>
          <Grid container columnSpacing={2}>
            <Grid item md={12} sx={{ mt: 2, mb: 2, ml: 1, width: "100%" }}>
              <Autocomplete
                disablePortal
                fullWidth
                sx={{
                  width: "100%",
                }}
                id="combo-box-demo"
                inputValue={user}
                onChange={(event, newValue) => {
                  console.log("NEW ", newValue);
                  setStore(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                  console.log("INPUT ", newInputValue);
                  if (newInputValue) {
                    setUser(newInputValue);
                  } else {
                    setUser("");
                    setUsers([]);
                  }
                }}
                options={users}
                noOptionsText={
                  user?.length > 2 && (
                    <Typography>
                      If you couldn't found it in our databse click to:
                      <Button
                        onClick={() => {
                          setExtend((prev) => true);
                          setUser(null);
                          setStore({});
                        }}
                      >
                        Add new
                      </Button>
                    </Typography>
                  )
                }
                renderInput={(params) => (
                  <TextField {...params} label="Author name" />
                )}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="First name"
                onInput={(e) =>
                  setStore({ ...store, first_name: e.target.value })
                }
                fullWidth
                variant="standard"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Last name"
                onInput={(e) =>
                  setStore({ ...store, last_name: e.target.value })
                }
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="Email"
                type="email"
                onInput={(e) => setStore({ ...store, email: e.target.value })}
                fullWidth
                variant="standard"
              />
            </Grid>
          </Grid>
        </>
      )}
    </Container>
  );
}
