import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Typography } from "@mui/material";
const Tonalities = ({
  tonalities,
  setFilters,
  setUpdated,
  tonality,
  setTonality,
}) => {
  return (
    <FormControl fullWidth>
      <Typography id="demo-simple-select-label">Tonality</Typography>
      <Autocomplete
        multiple
        id="tags-outlined"
        defaultValue={tonality || []}
        limitTags={1}
        onChange={(event, newValue) => {
          setFilters((prev) => ({
            ...prev,
            advanced: { ...prev?.advanced, tonality: newValue },
          }));
          setTonality(newValue);
          setUpdated((prev) => prev + 1);
        }}
        options={tonalities || []}
        getOptionLabel={(option) => option?.name}
        sx={{
          width: "100%",
        }}
        filterSelectedOptions
        renderInput={(params) => <TextField {...params} />}
      />
    </FormControl>
  );
};

export default Tonalities;
