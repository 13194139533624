import { Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Title from "./Structure/Title/Edit";
import View from "./Structure/Title/View";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Location from "./Structure/Location/Location";
import Countries from "./Structure/Location/Countries";
import Meters from "./Structure/Features/Meters";
import Types from "./Structure/Title/Types";
import Modus from "./Structure/Features/Modus";
import BPM from "./Structure/Features/BPM";
import Level from "./Structure/Features/Level";
import Tonality from "./Structure/Features/Tonality";
import ServerRequests from "../../../Requests/Server";
import Authentication from "../../../Requests/UserData";
import moment from "moment";
import Date from "./Structure/Title/Date";
import Materials from "./Structure/Materials/Materials";
import Authors from "./Structure/Title/Authors";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const steps = ["Information", "Location", "Materials", "Features"];
const Forms = () => {
  const currentUser = Authentication.getCurrentUser();
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());
  const [open, setOpen] = React.useState(false);
  const isStepOptional = (step) => {};

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const [errors, setErrors] = React.useState(null);
  const informationValidation = async () => {
    const req = await ServerRequests.post_token(
      "home/informations_validation/",
      currentUser.auth_token,
      {
        titles: titles,
        date: date,
        types: types,
      }
    );
    if (!req.ok) {
      const data = await req.json();

      setErrors(data);
      return false;
    } else {
      setErrors(null);
      return true;
    }
  };

  const handleNext = () => {
    let newSkipped = skipped;

    informationValidation().then((result) => {
      if (result == true) {
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const [titles, setTitles] = React.useState([]);
  const [date, setDate] = React.useState(null);
  const [meter, setMeter] = React.useState(null);
  const [meters, setMeters] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [modu, setModu] = React.useState(null);
  const [bpm, setBPM] = React.useState([]);
  const [level, setLevel] = React.useState(null);
  const [tonality, setTonality] = React.useState([]);
  const [country, setCountry] = React.useState([]);
  const [region, setRegion] = React.useState([]);
  const [store, setStore] = React.useState({});
  const [mataus, setMataus] = React.useState([]);

  const handleSubmit = () => {
    setOpen(true);
    const timer = setTimeout(() => {
      ServerRequests.post_token("home/add_tune/", currentUser.auth_token, {
        required: {
          title: titles,
          types: types,
          date: date,
          author: store,
        },
        location: {
          country: country,
          region: region,
        },
        features: {
          materials: mataus,
          level: level,
          tonality: tonality,
          bpm: bpm,
          modus: modu,
          meters: meters,
        },
      }).then((result) => {
        handleNext();
        setOpen(false);
      });
    }, 2000);
    return () => clearTimeout(timer);

    // ;
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">Optional</Typography>
            );
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel
                {...labelProps}
                sx={{
                  flexFlow: "wrap",
                  justifyContent: "center",
                  fontSize: "0.75rem",
                }}
              >
                {label}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1, textAlign: "center" }}>
            Your tune get added successefully
          </Typography>
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box> */}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Box sx={{ height: 40 }} />
          {activeStep === 0 && (
            <Grid
              container
              maxWidth="false"
              spacing={{ xs: 0, md: 2 }}
              sx={{ margin: "50px auto" }}
            >
              <Grid
                item
                xs={12}
                md={8}
                sx={{
                  borderRight: "1px solid #ddd",
                  p: 2,
                }}
              >
                <Title titles={titles} errors={errors} setTitles={setTitles} />

                <br />
                <Types types={types} errors={errors} setTypes={setTypes} />
                <br />
                <Authors
                  date={date}
                  setDate={setDate}
                  store={store}
                  setStore={setStore}
                  errors={errors}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                sx={{ background: "#eee", width: "100%" }}
              >
                <Typography sx={{ fontWeight: 900 }}>
                  {" "}
                  Titles Preview
                </Typography>
                <Divider />
                <View titles={titles} setTitles={setTitles} />
              </Grid>
            </Grid>
          )}

          {activeStep === 1 && (
            <Grid container sx={{ mb: 5, mt: 2 }}>
              <Grid item md={12}>
                {" "}
                <Typography sx={{ fontWeight: 900 }}>
                  {" "}
                  Region / Country
                </Typography>
              </Grid>

              <Grid item md={6} xs={12}>
                <Countries country={country} setCountry={setCountry} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Location region={region} setRegion={setRegion} />
              </Grid>
            </Grid>
          )}

          {activeStep === 2 && (
            <Grid container spacing={1} sx={{ mb: 5, mt: 2 }}>
              <Grid item md={12} sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 900 }}> Materials</Typography>
                <Materials mataus={mataus} setMataus={setMataus} />
              </Grid>
            </Grid>
          )}

          {activeStep === 3 && (
            <Grid container spacing={2} sx={{ mb: 5, mt: 2 }}>
              <Grid item md={12} xs={12} sx={{ mb: 2 }}>
                <Typography sx={{ fontWeight: 900 }}> Features</Typography>
              </Grid>
              <Grid item md={3} xs={6}>
                <Typography sx={{ fontWeight: 900 }}>Level</Typography>
                <Level level={level} setLevel={setLevel} />
              </Grid>

              <Grid item md={3} xs={6}>
                {" "}
                <Typography sx={{ fontWeight: 900 }}>BPM</Typography>
                <BPM bpm={bpm} setBPM={setBPM} />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography sx={{ fontWeight: 900 }}>Tonality</Typography>
                <Tonality
                  tonality={tonality}
                  setTonality={setTonality}
                  modu={modu}
                  setModu={setModu}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <Typography sx={{ fontWeight: 900 }}>Meter</Typography>
                <Meters
                  meter={meter}
                  setMeter={setMeter}
                  meters={meters}
                  setMeters={setMeters}
                />
              </Grid>
            </Grid>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 10,
              pt: 1,
              pb: 1,
              background: "#eee",
              borderRadius: 2,
            }}
          >
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}

            <Button
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
            >
              {activeStep === steps.length - 1 ? "Save" : "Next"}
            </Button>
          </Box>
        </React.Fragment>
      )}
    </Box>
  );
};

export default Forms;
