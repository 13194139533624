import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

export default function Date({ errors, setDate, date }) {
  const [value, setValue] = React.useState(dayjs("2023-01-01T21:11:54"));

  const handleChange = (newValue) => {
    setValue(newValue);
  };
  console.log(date);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3} sx={{ m: 1, mt: 3, width: "100%" }}>
        <DatePicker
          views={["year"]}
          label="Year of Publishing"
          value={date}
          onChange={(newValue) => {
            setDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={errors?.date ? true : false}
              helperText={errors?.date}
            />
          )}
        />
      </Stack>
    </LocalizationProvider>
  );
}
