import React from "react";
import {
  Autocomplete,
  Button,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ServerRequests from "../../../../../Requests/Server";
import Authentication from "../../../../../Requests/UserData";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import BlurOffIcon from "@mui/icons-material/BlurOff";
import IconButton from "@mui/material/IconButton";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
const Meters = ({ meter, setMeter, meters, setMeters, profile, tune }) => {
  const currentUser = Authentication.getCurrentUser();
  const [counter, setCounter] = React.useState(meters?.length);

  const handleAdd = () => {
    if (meter !== null) {
      setMeters((prev) => [
        ...prev,
        { id: counter, meter: meter, editor: profile?.id, uid: -1 },
      ]);
      setCounter((prev) => prev + 1);
    }

    setMeter(null);
  };
  console.log(meter);
  console.log(meters);

  const handleDelete = (id, uid) => {
    if (uid != -1) {
      ServerRequests.delete_token(
        "tune/delete/meter/" + uid + "/" + tune + "/",
        currentUser.auth_token
      ).then((response) => console.log(response));
    }
    setMeters((prev) => prev.filter((item) => item.id !== id));
  };
  return (
    <Box>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item md={6} xs={6}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ p: 2 }}
          >
            <TextField
              id="outlined-number"
              label="No Beats"
              type="number"
              sx={{
                width: 120,
              }}
              value={meter?.beat || ""}
              onInput={(e) => setMeter({ ...meter, beat: e.target.value })}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <BlurOffIcon
              sx={{
                fontSize: "3rem",

                transform: "rotate(90deg)",
              }}
            />
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel
                shrink={true}
                focused={true}
                id="demo-simple-select-autowidth-label"
              >
                Note Value
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                value={meter?.notev || ""}
                onChange={(e) => setMeter({ ...meter, notev: e.target.value })}
                // autoWidth

                label="Note Value"
              >
                <MenuItem value="" selected>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={16}>16</MenuItem>
              </Select>
            </FormControl>
            <Button
              variant="contained"
              onClick={handleAdd}
              sx={{
                width: "100%",
                mt: 2,
              }}
            >
              Add Meter
            </Button>
          </Grid>
        </Grid>
        <Grid item md={6} xs={6}>
          <Typography sx={{ fontWeight: 900 }}>Meter Preview</Typography>
          <Divider />
          <List sx={{ width: "100%", bgcolor: "#eee" }}>
            {meters?.map((item, key) => (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemText
                    primary={item?.title}
                    secondary={
                      <React.Fragment>
                        {item?.editor === profile?.id && (
                          <IconButton
                            aria-label="Example"
                            onClick={(e) => handleDelete(item.id, item?.uid)}
                          >
                            <DeleteForeverIcon sx={{ color: "red" }} />
                          </IconButton>
                        )}
                        {item?.meter !== undefined
                          ? " — " + item?.meter?.beat + "/" + item?.meter?.notev
                          : " — " + item?.name}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </>
            ))}
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Meters;
