import { Chip, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import SignalCellularAltIcon from "@mui/icons-material/SignalCellularAlt";
import Paper from "@mui/material/Paper";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: 100,
}));
const Numbers = () => {
  return (
    <Container>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        columnSpacing={1}
        rowSpacing={1}
      >
        <Grid item md={4} xs={12}>
          <Item sx={{ backgroundColor: "#fffbf7" }}>
            <Typography textAlign="left" fontWeight={800} sx={{ mb: 1 }}>
              Performing
            </Typography>
            <Grid container alignItems="stretch" justifyContent="space-between">
              <SignalCellularAltIcon s={{ color: "yellow" }} />
              <Chip label="23 Tunes" />
            </Grid>
          </Item>
        </Grid>
        <Grid item md={4} xs={12}>
          <Item sx={{ backgroundColor: "#f6fbff" }}>
            <Typography textAlign="left" fontWeight={800} sx={{ mb: 1 }}>
              Teaching
            </Typography>
            <Grid container alignItems="stretch" justifyContent="space-between">
              <SignalCellularAltIcon s={{ color: "yellow" }} />
              <Chip label="5 Tunes" />
            </Grid>
          </Item>
        </Grid>
        <Grid item md={4} xs={12}>
          <Item sx={{ backgroundColor: "#f7fcf8" }}>
            <Typography textAlign="left" fontWeight={800} sx={{ mb: 1 }}>
              Editing
            </Typography>
            <Grid container alignItems="stretch" justifyContent="space-between">
              <SignalCellularAltIcon s={{ color: "yellow" }} />
              <Chip label="2 Tunes" />
            </Grid>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Numbers;
