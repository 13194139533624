import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { faker } from "@faker-js/faker";
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: 100,
  height: "100%",
}));
export const Charts = () => {
  ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
    Title
  );

  const data = {
    labels: ["Wish List", "Elementary", "Professional"],
    datasets: [
      {
        label: "# of Tunes",
        data: [12, 19, 3],
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Performing progress",
      },
    },
  };

  const [bardata, setBardata] = React.useState({
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Wish List",
        data: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ].map(() => faker.datatype.number({ min: 0, max: 20 })),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Elementary",
        data: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ].map(() => faker.datatype.number({ min: 0, max: 20 })),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Professional",
        data: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ].map(() => faker.datatype.number({ min: 0, max: 20 })),
        backgroundColor: "rgba(222, 10, 10, 0.5)",
      },
    ],
  });
  console.log(bardata);
  return (
    <Container sx={{ mt: 2 }}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="stretch"
        columnSpacing={1}
        rowSpacing={1}
      >
        <Grid item md={8}>
          <Item>
            <Bar options={options} data={bardata} />
          </Item>
        </Grid>
        <Grid item md={4}>
          <Item>
            <Pie data={data} />
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Charts;
