import { Divider, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import React from "react";

const History = () => {
  return (
    <Box>
      <Container>
        <Grid container columnSpacing={1}>
          <Grid item md={6} xs={12}>
            <Typography>Playing History</Typography>
            <Divider />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography>New Tunes History</Typography>
            <Divider />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default History;
