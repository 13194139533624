import { Container, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Charts from "./Charts";
import Numbers from "./Numbers";
import par1 from "../../../assets/partners/par1.png";
import par2 from "../../../assets/partners/par2.png";
import par3 from "../../../assets/partners/par3.png";
import par4 from "../../../assets/partners/par4.png";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
const itemData = [
  {
    img: par2,
    title: "Burger",
    rows: 1,
    cols: 2,
  },
  {
    img: par3,
    title: "Camera",
    rows: 1,
    cols: 2,
  },
  {
    img: par4,
    title: "Camera",
    rows: 1,
    cols: 2,
  },
  {
    img: par1,
    title: "Breakfast",
    rows: 1,
    cols: 6,
  },
];
function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
const Body = () => {
  return (
    <Box>
      <Numbers />
      {/* <Charts /> */}
      <Box sx={{ minHeight: "50vh" }} />
      <Container>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          <Grid item md={4}>
            <Typography fontWeight={900} textAlign="left" variant="h3">
              Partners
            </Typography>
          </Grid>

          <Grid item md={8}>
            <ImageList
              sx={{ width: "100%", px: 4 }}
              variant="quilted"
              cols={12}
              rowHeight={100}
            >
              {itemData.map((item) => (
                <ImageListItem
                  key={item.img}
                  cols={item.cols || 1}
                  rows={item.rows || 1}
                >
                  <img
                    {...srcset(item.img, 121, item.rows, item.cols)}
                    alt={item.title}
                    loading="lazy"
                    style={{ objectFit: "contain" }}
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Body;
