import React from "react";
import { CssBaseline } from "@mui/material";
import { Box, Container } from "@mui/system";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Draweror from "./Draweror";
import Choose from "./Choose";
import Checkbox from "@mui/material/Checkbox";
import { Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormLabel from "@mui/material/FormLabel";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const Filters = ({
  handleTitleSearch,
  handleResourceFilter,
  handleSorting,
  handleType,
  setFilters,
  setUpdated,
  types,
  filters,
  countries,
  competences,
  handleCompetenceChange,
  disablechoose,
  setDisablechoose,
  handleCountry,
}) => {
  const [resource, setResources] = React.useState("");

  const handleChange = (event) => {
    // setResources(event.target.value);
  };
  console.log(filters?.combinations);

  return (
    <Container
      maxWidth={false}
      sx={{ paddingLeft: "0 !important", paddingRight: "0 !important", mb: 1 }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Item>
            <Container>
              <Grid container spacing={1}>
                <Grid item md={5}>
                  <Paper
                    component="form"
                    sx={{
                      height: "100%",
                    }}
                  >
                    <Choose
                      setFilters={setFilters}
                      disablechoose={disablechoose}
                      setDisablechoose={setDisablechoose}
                      setUpdated={setUpdated}
                    />
                  </Paper>
                </Grid>
                <Grid item md={4} sx={{ width: "100%" }}>
                  <Paper
                    component="form"
                    sx={{
                      p: "2px 4px",
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by title"
                      inputProps={{ "aria-label": "search by title" }}
                      onInput={(e) => handleTitleSearch(e.target.value)}
                    />
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Autocomplete
                    multiple
                    id="tags-outlined"
                    limitTags={1}
                    onChange={(event, newValue) => {
                      handleCompetenceChange(newValue);
                    }}
                    options={competences || []}
                    getOptionLabel={(option) => option?.title}
                    sx={{
                      width: "100%",
                    }}
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Competence.." />
                    )}
                  />
                </Grid>

                <Grid container columnSpacing={4} item md={12} sx={{ mt: 1 }}>
                  <Grid item md={2} xs={12} sx={{ width: "100%" }}>
                    <Draweror setUpdated={setUpdated} setFilters={setFilters} />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="stretch"
                    item
                    md={10}
                    xs={12}
                  >
                    <Grid item xs={12} md={2}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={true}
                            onChange={(e) =>
                              handleResourceFilter(e.target.checked)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        }
                        label="Resources"
                      />
                      {filters?.users?.length > 0 && (
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                          <Typography id="demo-select-small">
                            Number of players
                          </Typography>
                          <Select
                            label="Number of players"
                            sx={{ width: "85%" }}
                            onChange={(event) => {
                              setFilters((prev) => ({
                                ...prev,
                                combinations: event.target.value,
                              }));
                              setUpdated((prev) => prev + 1);
                            }}
                            value={filters?.combinations}
                          >
                            <MenuItem value={0} disabled={true}>
                              Select
                            </MenuItem>
                            {filters?.users?.map((item, key) => (
                              <MenuItem value={key + 1}>{key + 1}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormControl fullWidth>
                        <Typography>Countries</Typography>

                        <Autocomplete
                          multiple
                          id="tags-outlined"
                          limitTags={1}
                          onChange={(event, newValue) => {
                            handleCountry(newValue);
                          }}
                          options={countries || []}
                          getOptionLabel={(option) => option?.name}
                          sx={{
                            maxWidth: 300,
                          }}
                          filterSelectedOptions
                          renderOption={(props, option) => (
                            <Box
                              component="li"
                              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                              {...props}
                            >
                              <img
                                loading="lazy"
                                width="20"
                                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.name} ({option.code})
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} placeholder="Types.." />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <InputLabel
                        sx={{
                          fontWeight: 800,
                        }}
                        id="demo-simple-select-autowidth-label"
                      >
                        Tune type
                      </InputLabel>
                      <Autocomplete
                        multiple
                        id="tags-outlined"
                        limitTags={1}
                        onChange={(event, newValue) => {
                          handleType(newValue);
                        }}
                        options={types || []}
                        getOptionLabel={(option) => option?.name}
                        sx={{
                          width: "100%",
                        }}
                        filterSelectedOptions
                        renderInput={(params) => (
                          <TextField {...params} placeholder="Types.." />
                        )}
                      />
                    </Grid>

                    <Grid item xs={2} md={2}>
                      <FormControl sx={{ minWidth: 80, width: "100%" }}>
                        <FormLabel
                          sx={{
                            fontWeight: 800,
                          }}
                          id="demo-row-radio-buttons-group-label"
                        >
                          Sort records
                        </FormLabel>
                        <Select
                          displayEmpty
                          onChange={(e) => handleSorting(e.target.value)}
                          inputProps={{ "aria-label": "Without label" }}
                          defaultValue=""
                          autoWidth
                        >
                          <MenuItem value="" disabled={true}>
                            Default
                          </MenuItem>

                          <MenuItem value="new">Newest first</MenuItem>
                          <MenuItem value="old">Old first</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </Item>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Filters;
const TuneTypes = [
  { title: "Type 1" },
  { title: "Type 2" },
  { title: "Type 3" },
];
